ol.main {
	counter-reset: li;
	padding-left: 2.5rem;
	padding: 0.25rem 0 0.25rem 2.75rem;
}
ol.main small {
	opacity: 0.3;
}
ol.main ol,
ol.main ul {
	padding: 0.75rem 0 0 1.5rem;
}
ol.main li {
	margin-bottom: 0.375rem;
}
ol.main > li {
	position: relative;
	counter-increment: li;
	min-height: 2rem;
	padding-top: 0.5rem;
	list-style: none;
}
ol.main > li::before,
ol.main > li::after {
	position: absolute;
	display: flex;
	opacity: 0.35;
}
ol.main > li::before {
	content: counter(li);
	left: -2.75rem;
	top: 0;
	height: calc(2rem - 1px);
	width: calc(2rem - 1px);
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1px solid currentcolor;
}
ol.main > li::after {
	content: "";
	left: -1.75rem;
	top: calc(2rem + 1px);
	height: calc(100% - 2.5rem);
	border-left: 1px solid currentcolor;
}
