@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap");

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	z-index: -30;
	@apply overflow-hidden;
	touch-action: none;
}

#root {
	position: relative;
	z-index: -30;
}

.pulse {
	animation: pulse-dot 1.75s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	@apply border-2 border-green-400 rounded-full border-opacity-30;
}

.poof {
	animation: poof 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

@keyframes poof {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	30% {
		opacity: 100;
		transform: scale(1);
	}
	60% {
		opacity: 100;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0);
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
		@apply border-2 border-opacity-40;
	}
	50% {
		transform: scale(1);
		@apply border-8 border-opacity-10;
	}
	100% {
		transform: scale(0.8);
		@apply border-opacity-0;
	}
}

.fixnum {
	-webkit-font-feature-settings: "lnum";
	-moz-font-feature-settings: "lnum";
	font-feature-settings: "lnum";
}

.background {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.entrance {
	animation: entrance 2s ease-in-out forwards;
}

a.entrance::before {
	transform: scale(0);
}

a.entrance:first-child {
	animation-delay: 0.5s;
}

button.entrance:nth-child(2) {
	animation-delay: 0.9s;
}

a.entrance:nth-child(3) {
	animation-delay: 1.3s;
}

button.entrance:nth-child(4) {
	animation-delay: 1.7s;
}

a.entrance:nth-child(5) {
	animation-delay: 1.7s;
}

a.entrance:nth-child(6) {
	animation-delay: 1.7s;
}

.appear {
	animation: appear 2s ease-in-out forwards;
	animation-delay: 2.5s;
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes entrance {
	0% {
		transform: scale(0);
	}
	80% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes slideout {
	0% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(-250px);
	}
}

.slideout {
	animation: slideout 0.5s ease 0s 1 normal forwards;
}

.slidein {
	animation: slidein 0.5s ease 0s 1 normal forwards;
}

@keyframes slidein {
	0% {
		opacity: 0;
		transform: translateX(250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.pulsate {
	box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(34, 197, 94, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
